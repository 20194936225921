<template lang="pug">
.analytics_company
  company-wrapper(
    ref="refCompanyWrapper"
    v-model:search-form="searchForm"
    v-model:selected-company="selectedCompany"
    v-model:show-analytics="showAnalytics"
    :tab="tab"
    :cards="filters"
    :placeholder="placeholder"
    @reset:all="reset"
    @reset:data="resetBeforeFetch"
    @reset:form="resetSearchForm"
    @fetch:analyse="fetchAll"
  )
  average-cards(
    v-if="showAnalytics"
    :cards="averageCards"
    :data="commonCards.data"
    :info="commonCards.info"
  )
  statistics(
    v-if="showAnalytics"
    :tab="tab"
    :line-chart-data="monthlyChart"
    :map-chart-data="regionChart"
  )
  table-wrapper(
    v-if="showAnalytics"
    :tab="tab"
    :tabs="tabs"
    :entities-list="entitiesLists"
    :entities-list-info="entitiesListsInfo"
    :sorting-panels="sortingPanels"
    :excel-loader="excelLoader"
    v-model:mode="mode"
    v-model:sorting-form="sortingForm"
    @reset-entity="resetEntity"
    @fetch-entity="fetchEntityPrev"
    @download-excel="downloadExcel"
  )
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeUnmount, nextTick, onMounted } from 'vue'
import TableWrapper from "@/components/pages/analytics/table/TableWrapper.vue";
import CompanyWrapper from "@/components/pages/analytics/searchPanel/CompanyWrapper.vue";
import AverageCards from "@/components/pages/analytics/statistics/average/AverageCards.vue";
import Statistics from "@/components/pages/analytics/statistics/Statistics.vue";
import type { PropType } from "vue";
import type { TabMode } from "@/stores/search/SearchFormInterface";
import useAnalyticsCustomersStore from "@/stores/analytics/useAnalyticsCustomersStore";
import useAnalyticsSuppliersStore from "@/stores/analytics/useAnalyticsSuppliersStore";
import { useAnalytics } from "@/use/analytics/useAnalytics";
import { storeToRefs } from "pinia";
import { assignSorting, tagsToIdList } from "@/utils/searchForm/searchFormWorker";
import { downloadAnalyticExcel } from "@/utils/download/fileDownloader";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";

export default defineComponent({
  name: "CompanyPage",
  components: {
    Statistics,
    AverageCards,
    CompanyWrapper,
    TableWrapper,
  },
  props: {
    tab: {
      type: String as PropType<Exclude<TabMode, 'LotsListSearch'|'AnalyseIndustry'>>,
      default: '',
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    averageCards: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {

    const interfaceStore = useInterfaceSettingsStore();
    const companyStore = props.tab === 'AnalyseSuppliers' ? useAnalyticsSuppliersStore() : useAnalyticsCustomersStore();

    const refCompanyWrapper = ref();

    const {
      mode,
      selectedCompany,
      searchForm,
      sortingForm,
    } = storeToRefs(companyStore);

    const {
      resetSearchForm,
      resetSortingForm,
    } = companyStore;

    const {
      commonCards,
      entitiesLists,
      entitiesListsInfo,
      monthlyChart,
      regionChart,
      sortingPanels,
      resetEntity,
      fetchEntity,
      fetchAllAnalyse,
      resetFetchingData,
    } = useAnalytics(props.tab);

    const showAnalytics = ref(false);
    const excelLoader = ref(false);

    function downloadExcel() {
      const titleCompany = (selectedCompany.value?.title ? selectedCompany.value?.title : refCompanyWrapper.value?.selectedCompanyDetails?.[0]?.shortTitle) || '';
      const fetchData = assignSorting(tagsToIdList(searchForm.value, props.tab), sortingForm.value[mode.value])

      downloadAnalyticExcel(excelLoader, fetchData, mode.value, props.tab === 'AnalyseSuppliers' ? 'suppliers' : 'customers', titleCompany)
    }

    function reset() {
      selectedCompany.value = null
      resetSearchForm()
      if (!interfaceStore.functions.saveAnalyticsSortingOnFiltersChange) resetSortingForm()
      resetFetchingData()
    }

    function resetBeforeFetch() {
      if (!interfaceStore.functions.saveAnalyticsSortingOnFiltersChange) resetSortingForm()
      resetFetchingData()
    }

    /** for cancelling pending requests */
    onBeforeUnmount(() => {
      nextTick(() => resetFetchingData())
    })

    function fetchAll() {
      if (selectedCompany.value) {
        fetchAllAnalyse(searchForm.value, sortingForm.value)
      }
    }

    function fetchEntityPrev() {
      const fetchData = assignSorting(tagsToIdList(searchForm.value, props.tab), sortingForm.value[mode.value])
      fetchEntity(fetchData, mode.value, props.tab === 'AnalyseSuppliers' ? 'suppliers' : 'customers')
    }

    return {
      mode,
      refCompanyWrapper,
      commonCards,
      searchForm,
      selectedCompany,
      sortingForm,
      monthlyChart,
      regionChart,
      entitiesLists,
      entitiesListsInfo,
      sortingPanels,
      showAnalytics,
      excelLoader,
      downloadExcel,
      resetEntity,
      fetchEntityPrev,
      fetchAll,
      reset,
      resetBeforeFetch,
      resetSearchForm,
    }
  }
})
</script>

<style scoped lang="scss">
.analytics_company {
  display: flex;
  flex-flow: column;
  gap: 40px;
}
</style>
